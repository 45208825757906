/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from './hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

const AuthProvider = ({ children }: {
	children: ReactElement
}) => {
	const [token, setToken] = useLocalStorage('token');
	const [accountId, setAccountId] = useLocalStorage('accountId');
	const navigate = useNavigate();

	// call this function when you want to authenticate the user
	const login = (token: any, username: any, callbackUrl: any) => {
		console.log(`login: ${username}`);
		setToken(token);
		setAccountId(username);
		navigate(callbackUrl || '/admin', { replace: true });
	};

	// call this function to sign out logged in user
	const logout = () => {
		console.log('logout');
		setToken();
		setAccountId();
		navigate('/admin', { replace: true });
	};

	return <AuthContext.Provider value={{
		token,
		accountId,
		login,
		logout
	}}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
	children: PropTypes.element
};

export { AuthProvider };

const useAuth = () => {
	return useContext(AuthContext);
};

export default useAuth;
