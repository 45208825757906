// const hostname = window && window.location && window.location.hostname;

import { URL } from 'url';

// const env = hostname === 'localhost' ? 'development' : 'production';
const env = process.env.REACT_APP_ENVIRONMENT ?? 'production';
if (env !== 'production') {
	console.log(env);
}

interface config {
	ENV: string;
	OB_ROLE: string;
	HOST: string | URL;
	S3: string | URL;
}

const production:config = {
	ENV: 'production',
	OB_ROLE: 'admin',
	HOST: 'https://api.dotsco.co',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/'
};

const development:config = {
	ENV: 'production',
	OB_ROLE: 'admin',
	HOST: 'http://localhost:3000',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/'
};

const staging: config = {
	ENV: 'staging',
	OB_ROLE: 'admin',
	HOST: 'https://stage.api.dotsco.co',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/'
};

const config: {
	[key: string]: any;
} = {
	development,
	production,
	staging
};

export default config[env];

export const API_ROOT = `${config[env].HOST}`;

export const IMAGE_DOMAIN = `${config[env].S3}`;

export const getAPIURL = (resourceURL: string | URL) => `${config[env].HOST}/${resourceURL}`;
