/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SidebarRoutes } from './constant';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Log from '../assets/images/log-out.svg';
import useAuth from '../AuthProvider';
//import ScrollPane from './ScrollPanel';
import { ScrollPanel } from 'primereact/scrollpanel';

const Sidebar:React.FC = () => {
	// const [menuState, setMenuState] = useState(false);
	const location = useLocation();
	const { logout } = useAuth() as any;

	// const toggleMenuState = () => {
	// 	setMenuState(!menuState);
	// };

	useEffect(() => {
		onRouteChanged();
	}, [location]);

	const onRouteChanged = () => {
		document.querySelector('#sidebar')?.classList.remove('active');
		// setMenuState(false);
	};

	// const logout = () => {
	// 	localStorage.clear();
	// };

	const isPathActive = (path: string) => {
		return location.pathname.startsWith(path);
	};

	return (
		<ScrollPanel
			style={{
				width: '255px',
				height: '100vh',
				padding: '0',
				paddingTop: '20px',
				zIndex: '11',
				background: '#ffffff'
			}}
			className='purpleScrollbar'>
			<nav
				className='sidebar sidebar-offcanvas'
				id='sidebar'>
				{/* <div className='text-center sidebar-brand-wrapper d-flex justify-content-center align-items-center'>
				 <img src={Logo} alt="logo" width="60px" />
			</div> */}
				<ul className='nav'>
					{SidebarRoutes.map((p, i) => (
						<OverlayTrigger
							key={i+1}
							placement='right'
							show={false}
							overlay={(props) => (
								<Tooltip
									id='button-tooltip'
									{...props}></Tooltip>
							)}>
							<li
								key={i}
								className={isPathActive(p.path) ? 'nav-item active side-active' : 'nav-item side-inactive'}>
								<Link
									className='nav-link'
									to={p.path}>
									<img
										src={`${p.icon}`}
										alt='...'
									/>
									<p className='side-nav-name'>{p.displayName}</p>
									{/*   <i className={`fa ${p.icon} menu-icon`}></i> <p className="side-nav-name">{p.displayName}</p>
                   <span className="menu-title">{p.displayName}</span> */}
								</Link>
							</li>
						</OverlayTrigger>
					))}

					<OverlayTrigger
						placement='right'
						show={false}
						overlay={(props) => (
							<Tooltip
								id='button-tooltip'
								{...props}></Tooltip>
						)}>
						<li
							className='nav-item side-inactive'
							onClick={(e) => {
								e.preventDefault();
								logout();
							}}>
							<Link
								className='nav-link'
								to='/login'>
								<img
									src={Log}
									alt='...'
								/>
								<p className='side-nav-name'>Logout</p>
							</Link>
						</li>
					</OverlayTrigger>
				</ul>
			</nav>
		</ScrollPanel>
	);
};

export default Sidebar;
