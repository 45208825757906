import { API_ROOT } from '../../api/config';
import { toast } from 'react-toastify';
import { convertObjectToUrlparams } from '../services/UtilService';

/* todo: Refactor this after URL constants */
function sanitizeUrl(url: string, data: any) {
	return data ? url + convertObjectToUrlparams(data) : url;
}

export function parseError(error: any) {
	return error || 'Something went wrong';
}

export default function request(url: string, options:any = {}, skipAuthToken?: boolean | undefined) {
	const config = {
		method: 'GET',
		...options
	};
	const errors = [];

	if (!url) {
		errors.push('url');
	}

	if (!config.payload && config.method !== 'GET' && config.method !== 'DELETE') {
		errors.push('payload');
	}

	if (errors.length) {
		throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
	}
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		...(!skipAuthToken && {
			Authorization: `Bearer ${localStorage.getItem('token')}`
		}),
		...config.headers
	};

	const params:any = {
		headers,
		method: config.method,
	};

	if (params.method !== 'GET') {
		params.body = JSON.stringify(config.payload);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks

	return fetch(`${API_ROOT}${sanitizeUrl(url, config.urlData)}`, params).then(async (response: any) => {
		if (response.status > 299) {
			const contentType = response.headers.get('content-type');
			switch (response.status) {
				case 401:
				case 403:
					toast.warn('Authentication Error!', {
						position: 'top-right'
					});
					throw response.status;
				case 405:
					toast.warn('Operation is not permitted');
					break;
				case 422:
					toast.warn(response.message ? response.message : 'Cannot perform action');
					break;
				case 503:
					toast.warn('Oops... Something went wrong.');
					break;
				default:
					break;
			}
			if (response.status === 404) {
				return { status: response.status, error: true };
			}

			if (response.status === 400) {
				const responseJson = await response.json();
				return {
					response: responseJson,
					status: response.status,
					error: true
				};
			}
			throw {
				status: response.statusText,
				response: {
					status: response.status,
					data: contentType && contentType.includes('application/json') ? await response.json() : await response.text()
				}
			};

			// if (contentType && contentType.includes('application/json')) {
			// 	error.response = {
			// 		status: response.status,
			// 		data: await response.json()
			// 	};
			// } else {
			// 	error.response = {
			// 		status: response.status,
			// 		data: await response.text()
			// 	};
			// }

			// throw error;
		} else {
			const contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				if (config.toast) {
					toast(config.toast);
				}
				return response.json();
			}

			return response.text();
		}
	});
}
