import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLocalStorage } from './app/hooks/useLocalStorage';

const AuthenticatedRoute = ({ children }: any): any => {
	const [token] = useLocalStorage('token');
	const location = useLocation();
	if (!token || (token == null)) {
		// user is not authenticated
		return <Navigate replace to='login' state = {{ from: location.pathname }} />;
	}
	return children;
};

AuthenticatedRoute.propTypes = {
	children: PropTypes.element
};

export default AuthenticatedRoute;
